export default [
	{
		code: 'en',
		file: 'en.js',
	},
	{
		code: 'es',
		file: 'es.js',
	},
];
